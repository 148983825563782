<template>
  <div class="logistics-services">
    <div class="w ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/cold-chain-image/page30.png" alt="">
      </div>

      <div class="text">
        穗深冷链物流事业部，为客户提供冷链仓储、城市配送、冷仓代运营、个性化温控包装运输、信息流转等综合冷链物流服务，管理团队具备多年的冷链行业物流经验，致力成为客制化冷链物流解决方案领导者。
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive, watchEffect, toRefs,
} from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.logistics-services {
  width: 100vw;
  height: 29.688vw;
  background-image: url('~@/assets/images/cold-chain-image/page04.png');
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 100% 100%;
  overflow: hidden;

  .title-img {
    width: 31.51vw;
    height: 5.938vw;
    margin-top: 4.427vw;
    margin-bottom: 3.229vw;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text {
    text-indent: 2.084vw;
    width: 41.875vw;
    font-size: 1.042vw;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 2.083vw;
  }
}
@media screen and (max-width: 768px) {
  .logistics-services {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/cold-chain-image/page04.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: auto 100%;
    overflow: hidden;

    .title-img {
      width: 40vw;
      height: auto;
      margin-top: 4.427vw;
      margin-left: 4vw;
      margin-bottom: 3.229vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      padding: 1.333vw;
      padding-bottom: 2.667vw;
      text-indent: 3.733vw;
      width: 100%;
      font-size: 1.867vw;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 4.267vw;
    }
  }
}
</style>
