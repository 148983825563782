<template>
  <div class="cold-chain">
    <div class="bgc">
      <div class="w">
        <div class="title-img tx1200" ref="transform1">
          <img src="@/assets/images/cold-chain-image/page07.png" alt="">
        </div>
      </div>
            <div class="mark-box"></div>
    </div>

    <!-- 冷链物流服务 -->
    <logistics-services ref="logisticsServicesRef" :isView="logisticsServicesView"/>
    <!-- 服务内容 -->
    <service-content ref="serviceContentRef" :isView="serviceContentView"/>
    <!-- 核心优势 -->
    <core-advantages ref="coreAdvantagesRef" :isView="coreAdvantagesView"/>
    <!-- 贴心的物流交付 -->
    <logistics-delivery ref="logisticsDeliveryRef" :isView="logisticsDeliveryView"/>
    <!-- 合作客户 -->
    <cooperative-clients ref="cooperativeClientsRef" :isView="cooperativeClientsView"/>
  </div>
</template>

<script>
import LogisticsServices from './components/logistics-services.vue';
import ServiceContent from './components/service-content.vue';
import CoreAdvantages from './components/core-advantages.vue';
import LogisticsDelivery from './components/logistics-delivery.vue';
import CooperativeClients from './components/cooperative-clients.vue';
import {
  onMounted, onUnmounted, reactive, toRefs,
} from 'vue';
import { isElementInViewport, itemGsap } from '../index/js/usePage';

export default {
  components: {
    LogisticsServices,
    ServiceContent,
    CoreAdvantages,
    LogisticsDelivery,
    CooperativeClients,
  },
  setup() {
    const data = reactive({
      transform1: null,
      logisticsServicesView: false,
      serviceContentView: false,
      coreAdvantagesView: false,
      logisticsDeliveryView: false,
      cooperativeClientsView: false,
      logisticsServicesRef: null,
      serviceContentRef: null,
      coreAdvantagesRef: null,
      logisticsDeliveryRef: null,
      cooperativeClientsRef: null,
    });
    const scroll = () => {
      data.logisticsServicesView = isElementInViewport(data.logisticsServicesRef.$el);
      data.serviceContentView = isElementInViewport(data.serviceContentRef.$el);
      data.coreAdvantagesView = isElementInViewport(data.coreAdvantagesRef.$el);
      data.logisticsDeliveryView = isElementInViewport(data.logisticsDeliveryRef.$el);
      data.cooperativeClientsView = isElementInViewport(data.cooperativeClientsRef.$el);
    };
    let app = null;

    onMounted(() => {
      scroll();
      app = document.querySelector('#app');
      app.addEventListener('scroll', scroll);
      itemGsap(data.transform1, {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: 'none',
      });
    });
    onUnmounted(() => {
      app.removeEventListener('scroll', scroll);
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.cold-chain {
  .bgc {
    width: 100vw;
    height: 36.979vw;
    background-image: url('~@/assets/images/cold-chain-image/page02.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50% 50%;
    overflow: hidden;
        position: relative;
    .title-img {
      width: 25.625vw;
      height: 5.104vw;
      margin-top: 21.458vw;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -2.5vw;
        height: 0.313vw;
        width: 6.979vw;
        left: 0;
        background-color: #fff;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mark-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      // background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0,0.9));
      background-image: url('~@/assets/images/home-image/page99.png');
      background-position: 50% 100%;
      background-size: 100% 20%;
      background-repeat: no-repeat;
    }
  }
}
@media screen and (max-width: 768px) {
  .cold-chain {
    .bgc {
      width: 100vw;
      height: 50vw;
      background-image: url('~@/assets/images/cold-chain-image/page02.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 50% 50%;
      overflow: hidden;
      .title-img {
        width: 25.625vw;
        height: 5.104vw;
        margin-top: 40vw;
        margin-left: 4vw;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          bottom: -2.5vw;
          height: 0.313vw;
          width: 6.979vw;
          left: 0;
          background-color: #fff;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
