<template>
  <div class="service-content">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/cold-chain-image/page18.png" alt="">
      </div>

      <div class="item-box">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="text">{{ item.text }}</div>
          <div class="posi-img">
            <img class="img1" :src="item.img" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { itemGsap } from '@/views/index/js/usePage';
import { reactive, toRefs, watchEffect } from 'vue';
import img1 from '@/assets/images/cold-chain-image/page29.png';
import img2 from '@/assets/images/cold-chain-image/page10.png';
import img3 from '@/assets/images/cold-chain-image/page12.png';
import img4 from '@/assets/images/cold-chain-image/page13.png';
import img5 from '@/assets/images/cold-chain-image/page14.png';
import img6 from '@/assets/images/cold-chain-image/page15.png';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const list = [
      {
        title: '冷链园区代运营',
        text: '单体冷库、冷链园区代运营服务（业务招商、仓配运营、物业管理等）',
        img: img1,
      },
      {
        title: 'B2C仓储+一件代发',
        text: '冷链B2C货物储存以及快递包裹代发货业务',
        img: img2,
      },
      {
        title: 'B2B仓储+城市配送',
        text: '冷链B2B货物储存以及城市配送业务',
        img: img3,
      },
      {
        title: '冷链运输服务',
        text: '利用冷藏车、温控包装为客户实现小批量多频次的仓间调拨、城市配送等运输服务',
        img: img4,
      },
      {
        title: '客制化冷链解决方案',
        text: '根据客户需求提供全场景、定制化的包装方案、运输、配送方案等',
        img: img5,
      },
      {
        title: '“穗申”专线+揽派',
        text: '广东 - 上海冷链零担专线及揽件、派件服务',
        img: img6,
      },
    ];

    const data = reactive({
      count: 0,
      transformRef1: null,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          opacity: 1,
          duration: 1.5,
          y: 0,
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.service-content {
  width: 100vw;
  height: 51.979vw;
  overflow: hidden;
  background-image: url('~~@/assets/images/cold-chain-image/page05.png');
  transition: height 0.5s, opacity 0.5s;
  background-position: 50% 50%;
  background-size: 100% 100%;
  .content {
    .title-img {
      width: 31.563vw;
      height: 6.25vw;
      margin-top: 5.573vw;
      margin-bottom: 3.229vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 25vw;
        flex-basis: 25vw;
        height: 14.063vw;
        background: #fff;
        margin-bottom: 1.458vw;
        padding: 2.604vw 2.917vw;
        transition: all 0.3s;
        position: relative;

        .title {
          height: 1.458vw;
          font-size: 1.458vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: var(--baseColor1);
          line-height: 1.719vw;
          margin-bottom: 1.25vw;
        }
        .text {
          height: 2.813vw;
          font-size: 0.938vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          line-height: 1.875vw;
        }
        &:hover {
          background: #988058;
        }
        &:hover .title,
        &:hover .text {
          color: #fff;
        }
        .posi-img {
          position: absolute;
          right: 0.573vw;
          bottom: 1.042vw;
          width: 6.927vw;
          height: 3.49vw;
          z-index: 10;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
          .img2 {
            display: none;
          }
        }
        &:hover {
          .posi-img {
            .img1 {
              display: none;
            }
            .img2 {
              display: block;
            }
          }
        }
        &:nth-child(1) {
          .posi-img {
            width: 4.219vw;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-content {
    width: 100vw;
    height: auto;
    overflow: hidden;
    background-image: url('~~@/assets/images/cold-chain-image/page05.png');
    transition: height 0.5s, opacity 0.5s;
    background-position: 50% 50%;
    background-size: auot 100%;
    .content {
      .title-img {
        width: 50vw;
        height: auto;
        margin-top: 4vw;
        margin-left: 4vw;
        margin-bottom: 3.229vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.333vw;
        .item {
          width: 46vw;
          flex-basis: 46vw;
          padding: 2.667vw;
          height: auto;
          background: #fff;
          margin-bottom: 4vw;
          transition: all 0.3s;
          position: relative;

          .title {
            height: auto;
            font-size: 3.2vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: var(--baseColor1);
            line-height: 5.867vw;
            margin-bottom: 0.55vw;
          }
          .text {
            height: auto;
            font-size: 1.867vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            line-height: 4.267vw;
          }
          &:hover {
            background: #988058;
          }
          &:hover .title,
          &:hover .text {
            color: #fff;
          }
          .posi-img {
            position: absolute;
            right: 0.573vw;
            bottom: 1.042vw;
            width: 9.927vw;
            height: 6.49vw;
            z-index: 10;
            img {
              display: block;
              width: 100%;
              height: 100%;
            }
            .img2 {
              display: none;
            }
          }
          &:hover {
            .posi-img {
              .img1 {
                display: none;
              }
              .img2 {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
